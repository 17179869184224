<template>
    <div :key="$route.fullPath" class="container">
        <div class="error-500">
            <h1><span title="Server error">500</span> |</h1>
            <router-link class="mt-3" :to="{ name: 'ClientList' }">To main page</router-link>
            <h1>|</h1>
            <span class="col-6 mt-3">{{ displayErrorMessage }}</span>
        </div>
    </div>
</template>

<script>
/* eslint-disable vue/multi-word-component-names */

export default {
    name: 'Error500',
    computed: {
        displayErrorMessage() {
            return this.$route.query.error || 'Server error'
        },
    },
}
</script>

<style scoped>
.container {
    background-color: #f6f6f6;
    font-family: Roboto, serif !important;
    width: 500px;
    margin: 200px auto;
}

.error-500 {
    display: flex;
    padding: 5px;
}

h1 {
    font-size: 36px;
}

p {
    font-size: 18px;
    line-height: 1.5;
}

a {
    text-decoration: none;
    margin: 10px;
    color: #000;
}
</style>
